var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.properties.accounts, function (account) {
      return _c("v-chip", { key: account, staticClass: "mr-2 mb-2" }, [
        _vm._v(_vm._s(account)),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }