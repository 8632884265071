
import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/types';
import { Getter, State } from 'vuex-class';
import { users } from '@/store/modules/easy-firestore/users';
import { copyToClipboard } from '@/util/copy-to-clipboard';

import AvatarComponent from '@/components/common/AvatarComponent.vue';
import ParametersList from '@/components/data-operations/common/item/parameters/ParametersList.vue';
import ProfileAccounts from './ProfileAccounts.vue';

@Component({
	components: { AvatarComponent, ParametersList },
})
export default class InformationTab extends Vue {
	@State((state) => Object.values(state.users.data)[0]) currentUser!: User;

	@Getter('user/user') user!: User;

	mounted() {
		this.$store.dispatch(`${users.moduleName}/fetchById`, this.user.uid);
	}

	saveUserInformation(key: string, value: string) {
		const lowerCaseKey = key.toLowerCase();

		this.$store.dispatch(`${users.moduleName}/patch`, {
			id: this.user.uid,
			[lowerCaseKey]: value,
		});
	}

	copyInformationValue(informationValue: string) {
		// TODO: Add visual validation
		copyToClipboard(informationValue);
	}

	get generalInformation() {
		return [
			{
				icon: 'mdi-account',
				label: 'Title',
				placeholder: 'Enter a title',
				value: this.currentUser ? this.currentUser.title : '',
			},
			{
				icon: 'mdi-email-outline',
				label: 'Email',
				placeholder: 'Enter an email',
				value: this.currentUser ? this.currentUser.email : '',
			},
			{
				icon: 'mdi-phone',
				label: 'Phone',
				placeholder: 'Enter a phone number',
				value: this.currentUser ? this.currentUser.phone : '',
			},
			{
				icon: 'mdi-map-marker',
				label: 'Location',
				placeholder: 'Enter a location',
				value: this.currentUser ? this.currentUser.location : '',
			},
			{
				icon: 'mdi-clock-outline',
				label: 'Timezone',
				placeholder: 'Enter a timezone',
				value: this.currentUser ? this.currentUser.timezone : '',
			},
		];
	}

	get userIdentity() {
		return [
			{ id: 'email', label: 'Email', value: this.user.email },
			{ id: 'emailVerified', label: 'Email Verified', value: this.user.emailVerified },
			{ id: 'creationTime', label: 'Creation Time', value: this.user.metadata.creationTime },
		];
	}

	get userRolesAndAccounts() {
		return [
			{ id: 'studioRoles', label: 'Role', value: this.user.studioRoles },
			{
				id: 'userAccounts',
				label: 'User Accounts',
				component: ProfileAccounts,
				properties: {
					accounts: this.user.accounts,
				},
			},
		];
	}
}
