var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "py-0" },
    [
      _c(
        "v-row",
        { staticClass: "px-6 py-12" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "4" } },
            [
              _c("avatar-component", {
                staticClass: "d-block mx-auto mb-5",
                attrs: { user: _vm.user, size: "x-large" },
              }),
              _c("div", { staticClass: "mb-6 text-center" }, [
                _c("h2", { staticClass: "text-h5 mb-0" }, [
                  _vm._v(_vm._s(_vm.user.displayName)),
                ]),
              ]),
              _vm._l(_vm.generalInformation, function (information) {
                return _c(
                  "div",
                  {
                    key: information.label,
                    staticClass: "d-flex align-center mb-2",
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-3 pa-1 icon",
                        attrs: { color: "primary", small: "" },
                      },
                      [_vm._v(_vm._s(information.icon))]
                    ),
                    _c("span", { staticClass: "mr-3 text-body-2" }, [
                      _vm._v(_vm._s(information.label) + ":"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce:1s",
                          value: (value) =>
                            _vm.saveUserInformation(information.label, value),
                          expression:
                            "(value) => saveUserInformation(information.label, value)",
                          arg: "1s",
                        },
                      ],
                      staticClass:
                        "primary--text font-weight-light text-body-2 input",
                      attrs: {
                        type: "text",
                        placeholder: information.placeholder,
                      },
                      domProps: { value: information.value },
                    }),
                    information.value
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "ml-3",
                            attrs: { color: "primary", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.copyInformationValue(
                                  information.value
                                )
                              },
                            },
                          },
                          [_vm._v(" mdi-content-copy ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "8" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("ParametersList", {
                    attrs: {
                      groupTitle: "Identity",
                      description: "Identity of the user profile",
                      paramItems: _vm.userIdentity,
                    },
                  }),
                  _c("ParametersList", {
                    attrs: {
                      groupTitle: "Role and Accounts",
                      description: "The user role and the accounts",
                      paramItems: _vm.userRolesAndAccounts,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }